import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { colors } from '#styles.variables';

import { Controllers, Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import debug from '#services/debug.service';

import Form from './containers/Table';
import * as Styled from './style';
import { PromotionsService } from '#services/promotions';
import {
  PromotionType,
  PromotionsType,
} from '#services/promotions/dto/get-promotions.dto';

export default () => {
  const promotionService = new PromotionsService();

  const [selectedTab, setSelectedTab] = useState<PromotionsType>(
    PromotionsType.COUPON,
  );
  const [promotions, setPromotions] = useState<PromotionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [isLast, setIsLast] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getPromotions = async (filterFunction = false, showMore = false) => {
    try {
      if (showMore) {
        setLoading(true);
      }
      const params = {
        page: filterFunction ? 1 : page,
        size: 20,
        type: selectedTab,
      };
      const requestParams = !!searchValue.length
        ? { mindboxId: searchValue, ...params }
        : params;
      const response = await promotionService.getPromotions(requestParams);
      setPromotions((old: PromotionType[]) => {
        if (page === 1 || filterFunction) {
          return response.data;
        } else {
          return [...old, ...response.data];
        }
      });
      setPage((prevPageNumber) => prevPageNumber + 1);
      setIsLast(response.isLast);
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getPromotions ~ err:', e);
    } finally {
      if (showMore) {
        setLoading(false);
      }
    }
  };

  const handleShowPromotions = (value: PromotionsType) => {
    setPage(1);
    setSelectedTab(value);
  };

  const onPressTab = (value: PromotionsType) => {
    handleShowPromotions(value);
    // getPromotions();
  };

  useEffect(() => {
    getPromotions(true);
  }, [searchValue]);

  useEffect(() => {
    getPromotions(true);
  }, [selectedTab]);

  useBottomScrollListener(() => {
    if (!loading && !!promotions.length && !isLast) {
      getPromotions(false, true);
    }
  });

  return (
    <>
      <Head
        {...{
          label: 'Акции и купоны',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Controllers
        {...{
          searchInputProps: {
            value: searchValue,
            onChange: setSearchValue,
            placeholder: 'Поиск по ID акции',
          },
          name: '',
        }}
      />
      <Styled.FilterContainer>
        <Styled.FilterItem
          style={{
            background:
              selectedTab === PromotionsType.COUPON
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressTab(PromotionsType.COUPON)}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                selectedTab === PromotionsType.COUPON
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Купоны
          </p>
        </Styled.FilterItem>
        <Styled.FilterItem
          style={{
            background:
              selectedTab === PromotionsType.PROMOCODE
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressTab(PromotionsType.PROMOCODE)}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                selectedTab === PromotionsType.PROMOCODE
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Промокоды
          </p>
        </Styled.FilterItem>
        <Styled.FilterItem
          style={{
            background:
              selectedTab === PromotionsType.DISCOUNT
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressTab(PromotionsType.DISCOUNT)}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                selectedTab === PromotionsType.DISCOUNT
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Скидки
          </p>
        </Styled.FilterItem>
        <Styled.FilterItem
          style={{
            background:
              selectedTab === PromotionsType.NEWS
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressTab(PromotionsType.NEWS)}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                selectedTab === PromotionsType.NEWS
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Новости
          </p>
        </Styled.FilterItem>
        <Styled.FilterItem
          style={{
            background:
              selectedTab === PromotionsType.UNKNOWN
                ? colors.primary._100
                : colors.black._02,
          }}
          onClick={() => onPressTab(PromotionsType.UNKNOWN)}
        >
          <p
            className="filter_item_text"
            style={{
              color:
                selectedTab === PromotionsType.UNKNOWN
                  ? colors.black._00
                  : colors.black._80,
            }}
          >
            Неизвестно
          </p>
        </Styled.FilterItem>
      </Styled.FilterContainer>
      {loading && page === 1 ? (
        <LoadingContent />
      ) : (
        <Form data={promotions} loading={loading} />
      )}
    </>
  );
};
